export const HOME = "/";
export const SIGN_IN = "/login";
export const SIGN_UP = "/signup";
export const INVITATION_SIGN_UP = "/invitation_signup";
export const TIMESHEET = "/timesheet";
export const EMPLOYEES = "/employees";
export const CALENDAR = "/calendar";
export const CALENDAR_OVERVIEW = "/calendar-overview";
export const PROFILE = "/account_settings";
export const RESET_PASSWORD = "/reset-password";
export const PROJECTS = "/projects";
export const PROJECT_DETAIL = "/project/:name";
export const FORGOT_PASSWORD = "/forgot-password";
export const TEAMS = "/teams";
export const TEAM_DETAIL = "/team/:id";
export const REPORTS = "/reports";
export const REPORTS_DAILY = "/reports/daily";
export const DOCUMENTS = "/documents";
export const DAILY_WORK = "/daily-work";
export const WEEKLY_WORK = "/weekly-work";
export const EQUIPMENTS = "/equipments";
export const CANDIDATES = "/candidates";
export const CANDIDATE_INFO = "/candidates/:id";
export const PAYMENTS = "/payments";
export const Billing = "/billing";
export const COMPANY = "/company";
export const FINANCE = "/finance";
export const FINANCE_OVERVIEW = "/finance/overview";
export const FINANCE_INCOME_AND_EXPENSES = "/finance/income-and-expenses";
export const FINANCE_SALARY = "/finance/salaries";
export const JOB_POSTS = "/job-posts";
export const PROSPECTS = "/prospects";
export const JOB_POST_INFO = "/job-posts/:id";
export const LEADS = "/leads";
export const LEAD_INFO = "/leads/:id";
export const CLIENTS_AND_CONTACTS = "/clients-and-contacts";
export const NOTIFICATIONS = "/notifications";
export const POLLS = "/polls";
export const TEXTS = "/texts";
export const DIAGRAMS = "/diagrams";
export const CREATE_DIAGRAM = "/create-diagram";
export const DIAGRAM = "/diagram";
export const DIAGRAM_INFO = "/diagram/:id";
export const EDIT_DIAGRAM = "/edit-diagram";
export const EDIT_DIAGRAM_ID = "/edit-diagram/:id";
export const REVIEWS = "/reviews";
export const INVOICES = "/invoices";
export const REPORTS_OVERVIEW = "/reports/overview";
export const REPORTS_DOCUMENTS = "/documents/reports";
export const EMAIL_SYNC = "/email-sync";
export const INBOX = "/inbox";
export const SPACE = "/space/:id";
export const APPROVED_REPORTS = "/approved-reports";
