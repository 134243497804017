import React from "react";
import "./styles.css";
import Avatar from "react-avatar";
import { BACKEND_URL } from "constants/index";

const UserInfo = ({ user }) => (
  <div className={"sidebar-user-info"}>
    <div className={"companyName"}>
      <Avatar
        alt={user.company ? user.company.name : ""}
        src={`${BACKEND_URL}/media/${user.company.avatar}`}
        round={true}
        size={50}
        className={"avatar"}
        color="#cdcdce"
        margin="10px"
        style={{ marginRight: "10px" }}
      />
      <a
        href={user.company ? user.company.siteUrl : "#"}
        target="_blank"
        rel="noopener noreferrer"
      >
        {user.company ? user.company.name : ""}
      </a>
    </div>
  </div>
);

export default UserInfo;
