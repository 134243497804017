import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from './Button';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#594BF6',
        '&:hover': {
            backgroundColor: '#4C3FE1',
        },
    },
}));

const ButtonRoot = props => {
    const classes = useStyles();
    const { onClick, text, type, component } = props;
    return (
        <Button
            type={type}
            component={component}
            variant='contained'
            color='primary'
            disableElevation
            onClick={onClick}
            classes={{ containedPrimary: classes.root }}
            {...props}
        >
            {text}
        </Button>
    );
};

export default ButtonRoot;
