import React, { useState } from "react";
import ReactTable from "react-table";
import "./style.css";
import Pagination from "./Paginations";

const TableWrapper = (props) => {
  const [pageSize, setPageSize] = useState(20);

  const handlePageSize = (newValue) => {
    setPageSize(newValue);
  };

  const {
    toolbar,
    toolbarTitle,
    toolbarAction,
    headerRows,
    objects,
    showPagination,
    showPageJump,
    showPageSizeOptions,
    onRowClick,
    canHover,
    renderRowSubComponent,
  } = props;

  return (
    <>
      {toolbar && (
        <div className="table-header">
          {toolbarTitle ? <h4>{toolbarTitle}</h4> : null}
          {toolbarAction}
        </div>
      )}
      <ReactTable
        columns={headerRows}
        data={objects}
        minRows={0}
        PaginationComponent={Pagination}
        showPagination={showPagination}
        showPageJump={showPageJump}
        showPageSizeOptions={showPageSizeOptions}
        className={`${canHover ? "row_hover" : ""}`}
        pageSize={pageSize}
        onPageSizeChange={handlePageSize}
        getTrProps={(state, rowInfo, column) => ({
          onClick: () => {
            return onRowClick ? onRowClick(state, rowInfo, column) : null;
          },
        })}
        SubComponent={renderRowSubComponent}
      />
    </>
  );
};

export default TableWrapper;
